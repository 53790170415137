import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

// Import styles
import "./styles/team.scss";

// Bootstrap imports
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const ServiceList = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpService(sort: { order: ASC, fields: title }) {
        nodes {
          featuredImage {
            node {
              altText
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, formats: AUTO)
                }
              }
            }
          }
          serviceFields {
            description
          }
          slug
          title
        }
      }
    }
  `);

  // Wordpress data
  const { allWpService } = data;

  return (
    <div className="services">
      <Container className="fade-in-slow">
        <Row>
          {allWpService.nodes.map((service) => {
            return (
              <Col className="card-column" xs={12} sm={6} lg={4}>
                <Card>
                  {service.featuredImage ? (
                    <GatsbyImage
                      className="slide-image"
                      image={
                        service.featuredImage.node.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={service.featuredImage.node.altText}
                    />
                  ) : (
                    <StaticImage
                      className="slide-image"
                      src="../assets/images/placeholder.jpg"
                      alt="The Adaptix logo."
                      placeholder="tracedSVG"
                    />
                  )}
                  <Card.Body>
                    <h3 className="yell">{service.title}</h3>
                    <p className="talk">{service.serviceFields.description}</p>
                    <Link to={service.slug}>
                      <Button variant="accent">Read More</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default ServiceList;
